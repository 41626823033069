import { useCallback, useState } from 'react';
import { App, Button, Col, Form, Input, Modal } from 'antd';
import { EMAIL_REGEX } from '@/shared/constants';
import { getEmailCaptcha, resetPassword } from '@/lib/service/common';
import { useConfigProvider } from '@/lib/context';
import { useAsyncFn, useBoolean, useInterval } from 'react-use';
import './index.less';
import classNames from 'classnames';

export default function ResetPassword({ open, onCancel }: { open: boolean; onCancel?: () => void }) {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const { isMobile } = useConfigProvider();
  const [count, setCount] = useState<number>(0);
  const [isRunning, toggleIsRunning] = useBoolean(false);
  const [confirmState, putResetPassword] = useAsyncFn(resetPassword);
  const [sendEmailState, sendEmailCaptcha] = useAsyncFn(getEmailCaptcha);
  const handleFinish = useCallback(
    (values: { email: string; newPassword: string; resetCode: string }) => {
      putResetPassword({
        username: values.email,
        newPassword: values.newPassword,
        resetCode: values.resetCode,
        userType: 'STORE',
      }).then(() => {
        message.success('Your password was successfully reset.');
        form.resetFields();
        onCancel?.();
      });
    },
    [form, message, onCancel, putResetPassword],
  );

  useInterval(
    () => {
      if (count <= 0) {
        toggleIsRunning();
        setCount(0);
      } else {
        setCount((v) => --v);
      }
    },
    isRunning ? 1000 : null,
  );

  const sendEmail = useCallback(async () => {
    const email = form.getFieldValue('email');
    if (!EMAIL_REGEX.test(email) || /[\u4e00-\u9fa5]/.test(email)) {
      message.error('Please input a valid email address.');
      return;
    }
    await sendEmailCaptcha({ email: email });
    message.success('We just e-mailed you. Please check your e-mail for the verification code, and enter it below.');
    setCount(60);
    toggleIsRunning();
  }, [form, message, sendEmailCaptcha, toggleIsRunning]);

  return (
    <Modal
      width={isMobile ? '100vw' : 400}
      open={open}
      destroyOnClose
      wrapClassName={classNames('reset-password-dialog', { mobile: isMobile })}
      onCancel={onCancel}
      footer={false}
    >
      <span className="title">Reset Your Password</span>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          name="email"
          style={{ marginBottom: 0 }}
          rules={[
            { required: true, message: 'Enter your e-mail address' },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.resolve();
                } else if (!EMAIL_REGEX.test(value)) {
                  return Promise.reject(new Error('Please input a valid email address.'));
                } else if (/[\u4e00-\u9fa5]/.test(value)) {
                  return Promise.reject(new Error('Please input a valid email address in English.'));
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <Input placeholder="Enter your e-mail address" autoComplete="new-password" />
        </Form.Item>
        <div className="send-code-text">Send a verification code sent to your e-mail.</div>
        <div className="send-btn">
          <Button
            loading={sendEmailState.loading}
            className="action-button"
            type="primary"
            disabled={count > 0}
            onClick={sendEmail}
          >
            Send
          </Button>
        </div>
        <Form.Item
          name="resetCode"
          rules={[
            {
              required: true,
              message: 'Fill in your verification code.',
            },
          ]}
        >
          <Input
            placeholder={isMobile ? 'Fill in verification code' : 'Enter the verification code sent to your e-mail'}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item name="newPassword" rules={[{ required: true, message: 'Enter your new password.' }]}>
          <Input type="password" placeholder="Enter your new password." autoComplete="new-password" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={['newPassword']}
          rules={[
            { required: true, message: 'Enter new password' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Your new password doesn't match the password you entered in the confirmation text box."),
                );
              },
            }),
          ]}
        >
          <Input type="password" placeholder="Confirm new password" autoComplete="new-password" />
        </Form.Item>
        <Button
          type="primary"
          loading={confirmState.loading}
          htmlType="submit"
          size="large"
          className="action-button black"
        >
          {confirmState.loading ? 'Resetting Password...' : 'OK'}
        </Button>
      </Form>
    </Modal>
  );
}
