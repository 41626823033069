export enum ParamKey {
  HOMEPAGE_CATEGORY = 'tab',
  PAGE = 'page',
  SORT = 'sort',
  CATEGORY = 'cat',
  SEARCH = 'query',
  REGION = 'region',
  SAMPLE_PRICE = 'samplePrice',
  MOQ = 'moq',
  RECOMBEE_ID = 'recommId',
  FOCUS_ON_SEARCH = 'autofocus',
  SHOW_ONBOARDING = 'onboarding',
  TOKEN = 'token',
  PACKAGING_TAB = 'option',
}
