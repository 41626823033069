export interface CatalogItem {
  id: number;
  handle: string;
  catalogCategoryId: number;
  name: string;
  images: string[];
  rank: number;
  meta: {
    sampleOrder: string;
    sampleOrderPrice: number; // Sample price that supplier sets
    moqInfo: CatalogItemMoq[];
    sampleMOQ: number;
    description: string;
    type: CatalogItemType;
    editData?: any;
  };
  catalogCompanyId: number;
  catalogCompany: CatalogCompany;
  createdAt: Date;
  updatedAt: Date;
  enabledAt?: any;
  deletedAt?: any;
  catalogCategory: CatalogCategory;
  customizations: any[];
  supplierEditedAt?: any;
  displayPrice: number; // Sample price with bundled service fee inside
  perUnitPrice: number; // Sample price
  matched_fields?: string[];

  // custom data
  __recommId?: string;
  __percentOff?: string;
  __price: string;
}

export interface CatalogItemMoq {
  price: number;
  qty: number;
}

export declare type CatalogItemType = 'packaging' | 'services' | 'product';

export interface CatalogCategory {
  id: number;
  group: string;
  subgroup: string;
  vertical: string;
  images: string[];
  rank?: number;
  googleCategory?: string;
  googleCategoryId?: string | number;
  isLive?: boolean;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  enabledAt?: Date;

  _label?: string;
}

export interface CatalogCompany {
  __recommId?: string; // custom data
  __bestFor?: string; // custom data
  avatarUrl?: string;
  avgRespMinutes?: number;
  bio?: string;
  catalogUrls?: any[];
  categoryIds?: number[];
  city?: string;
  country?: string;
  countryCode?: string;
  createdAt?: Date;
  deletedAt?: Date;
  enabledAt?: Date;
  firstEnabledAt?: Date;
  id?: number;
  handle: string;
  meta?: any;
  name?: string;
  notableWorks?: string;
  noteAttributes?: string[];
  phone?: number | string;
  popularItems?: CatalogItem[];
  mostPopularImages?: { id: number; image: string }[];
  portfolio?: string[];
  productionCustomizations?: any[]; // TODO: get type interface
  productionEta?: number;
  samplesEta?: number;
  shippingTime?: number;
  searchKeywords?: string[];
  stateCode?: string;
  state?: string;
  type?: CatalogCompanyType;
  updatedAt?: Date;
  user?: any; // TODO: get type interface
  yearlySales?: string;
  bankInformation?: BankInformation;
  isPremiumSupplier?: boolean;
  requiredDeposit?: number;
  supplierMinNotes?: string;
  minsamplecost?: number;
  minProductionCost?: number;
  maxsamplecost?: number;
  minmoqqty?: number;
  matched_fields?: string[];
  lowestPricedMOQCatalogItem?: CatalogItem;
  isArchived: boolean;
  companyReviewCount?: number;
  companyReviewScore?: number;
  hasEnabledProducts?: boolean;
}

export enum CatalogCompanyType {
  SUPPLIER = 'SUPPLIER',
  SPECIALIST = 'SPECIALIST',
}

export interface BankInformation {
  companyAddress: string;
  companyName: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export enum SupplierInviteState {
  JOINED = 'JOINED',
  PENDING = 'PENDING',
}

export interface SupplierReview {
  id?: number;
  companyId: number;
  itemId: number;
  invoiceId: number;
  score: number;
  content: string;
  images: string[];
  buyerName: string;
  source: SupplierReviewsSource;
  createdAt?: string;
}

export enum SupplierReviewsSource {
  NONE = 'NONE',
  SUPPLIER = 'SUPPLIER',
  ITEM = 'ITEM',
  INVOICE = 'INVOICE',
}

export enum SearchRegion {
  AF = 'AF', // Africa
  NA = 'NA', // Northern Americas
  SA = 'SA', // Latin America and the Caribbean
  AS = 'AS', // Asia
  EU = 'EU', // Europe
  OA = 'OA', // Oceania
}

export enum SupplierSortKey {
  FEATURED = '-rank',
  ALPHABETICAL = 'name',
  NEWLY_ADDED = '-firstEnabledAt',
}

export enum ProductSortKey {
  NEWLY_ADDED = '-enabledAt',
  FEATURED = '-rank',
  PRICE_ASCENDING = 'productionMinPrice',
  PRICE_DESCENDING = '-productionMinPrice',
}

export interface CategoryOption {
  ids: number[];
  vertical: string;
}

export interface AutoCompleteResults {
  suppliers: CatalogCompany[];
  terms: string[];
}

export interface CategorySupplierSuggestion {
  label: string;
  supplierId: number;
}
