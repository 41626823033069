export enum EventName {
  COMMON_VIEW = 'common_view',
  USER_REGISTER = 'user_register',
}

export enum EventSource {
  ADD_TO_CART = 'ADD_TO_CART',
  AUDIENCE_CAPTURE_SIGNUP_FORM = 'AUDIENCE_CAPTURE_SIGNUP_FORM',
  BROWSER_SUPPLIER_CLICK_SEARCH_TERM = 'BROWSER_SUPPLIER_CLICK_SEARCH_TERM',
  BROWSER_SUPPLIER_CLICK_SEARCH_TERM_SUPPLIER = 'BROWSER_SUPPLIER_CLICK_SEARCH_TERM_SUPPLIER',
  BROWSER_SUPPLIER_LEFT_FILTER = 'BROWSER_SUPPLIER_LEFT_FILTER',
  BROWSER_SUPPLIER_SEARCH = 'BROWSER_SUPPLIER_SEARCH',
  BROWSER_SUPPLIER_TOP_NAV = 'BROWSER_SUPPLIER_TOP_NAV',
  BROWSE_SUPPLIER_SEE_PROFILE = 'BROWSE_SUPPLIER_SEE_PROFILE',
  BROWSE_SUPPLIER_SORT_RESULTS_FILTER_TAB = 'BROWSE_SUPPLIER_SORT_RESULTS_FILTER_TAB',
  EXISTING_BRANDS_GET_IN_TOUCH_FORM = 'EXISTING_BRANDS_GET_IN_TOUCH_FORM',
  EXISTING_BRANDS_SIGNUP_FORM = 'EXISTING_BRANDS_SIGNUP_FORM',
  FEATURED_PACKAGING_STARTING_POINT_CLICK = 'FEATURED_PACKAGING_STARTING_POINT_CLICK',
  HOMEPAGE = 'HOMEPAGE',
  HOMEPAGE_SEARCH = 'HOMEPAGE_SEARCH',
  HOMEPAGE_SEARCH_INTERSTITIAL_CATEGORY_CLICK = 'HOMEPAGE_SEARCH_INTERSTITIAL_CATEGORY_CLICK',
  HOME_FIND_SUPPLIER = 'HOME_FIND_SUPPLIER',
  LOCATION_FILTER_UPDATED = 'LOCATION_FILTER_UPDATED',
  MOQ_FILTER_UPDATED = 'MOQ_FILTER_UPDATED',
  PIETRA_PARTNERS_LANDING_PAGE = 'PIETRA_PARTNERS_LANDING_PAGE',
  PRICING = 'PRICING',
  BE_A_SUPPLIER = 'BE_A_SUPPLIER',
  PRODUCT_DETAILS_SELECT_VARIANTS = 'PRODUCT_DETAILS_SELECT_VARIANTS',
  RFP_STARTED = 'RFP_STARTED',
  SAMPLE_PRICE_FILTER_UPDATED = 'SAMPLE_PRICE_FILTER_UPDATED',
  SUPPLIER_PROFILE = 'SUPPLIER_PROFILE',
  SUPPLIER_PROFILE_VIEW_TAB = 'SUPPLIER_PROFILE_VIEW_TAB',
  USER_REGISTER_FORM = 'USER_REGISTER_FORM',
  AI_TSHIRT_GENERATOR = 'AI_TSHIRT_GENERATOR',
  CLOTHES_COLOR_CHANGER = 'CLOTHES_COLOR_CHANGER',
  PHOTO_BACKGROUND_CHANGER = 'PHOTO_BACKGROUND_CHANGER',
  AI_FABRIC_PATTERN_CHANGER = 'AI_FABRIC_PATTERN_CHANGER',
  AI_CLOTHING_MODEL_GENERATOR = 'AI_CLOTHING_MODEL_GENERATOR',
  A_B_TEST_EMAIL_COLLECTION_ON_HOMEPAGE_WITH_EMAIL_START = 'A_B_TEST_EMAIL_COLLECTION_ON_HOMEPAGE_WITH_EMAIL_START',
  A_B_TEST_EMAIL_COLLECTION_ON_HOMEPAGE_WITH_EMAIL_END = 'A_B_TEST_EMAIL_COLLECTION_ON_HOMEPAGE_WITH_EMAIL_END',
  A_B_TEST_EMAIL_COLLECTION_ON_HOMEPAGE_WITHOUT_EMAIL_START = 'A_B_TEST_EMAIL_COLLECTION_ON_HOMEPAGE_WITHOUT_EMAIL_START',
  A_B_TEST_EMAIL_COLLECTION_ON_HOMEPAGE_WITHOUT_EMAIL_END = 'A_B_TEST_EMAIL_COLLECTION_ON_HOMEPAGE_WITHOUT_EMAIL_END',
}
