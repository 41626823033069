import React from 'react';
import { FAQType } from '@/shared/types/subscription';
import { Faq } from '@/components/Common';
import classNames from 'classnames';
import './Faqs.less';

export interface FaqsProps {
  faqs: FAQType[];
  className?: string;
  CollapseIcon?: React.FC;
  ExpandIcon?: React.FC;
}

const Faqs = ({ faqs, className, CollapseIcon, ExpandIcon }: FaqsProps) => {
  return (
    <div className={classNames('faq-basic', className)}>
      {faqs.map((faq) => (
        <Faq key={faq.question} faq={faq} className="faq-item" ExpandIcon={ExpandIcon} CollapseIcon={CollapseIcon} />
      ))}
    </div>
  );
};

export default Faqs;
