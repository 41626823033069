export const randomUUID = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
    const salt = (Math.random() * 16) | 0;
    return (char === 'x' ? salt : (salt & 0x3) | 0x8).toString(16);
  });
};

export const getRandomSWRKey = (swrKey: string = 'swrkey-'): string => {
  return swrKey + '-' + randomUUID();
};
