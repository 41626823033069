import Link from 'next/link';
import Image from 'next/image';
import { FAQ_LINK, SUPPLIER_FAQ_LINK } from '@/shared/constants/web-constants';
import {
  BEGINNER_WORKSHOP_LINK,
  BUSINESS_BUILDER_LINK,
  SLACK_COMMUNITY_LINK,
  AFFILIATE_LINK,
} from '@/shared/constants/sourcing-marketplace';
import { useIntercom } from 'react-use-intercom';
import './index.less';

const ICON_SIZE = 24;

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const { boot } = useIntercom();

  const showIntercom = () => {
    boot();
  };

  return (
    <div className="pietra-footer utility-small">
      <div className="pietra-footer-content main">
        <div className="footer-info">
          <Image
            className="logo"
            src="/images/footer/logo-black.svg"
            width={77}
            height={20}
            alt="Pietra"
            title="Pietra"
            loading="lazy"
          />
          <p>Everything you need to start or scale your brand.</p>
          <h2>Backed by the best</h2>
          <div className="icons">
            <Image src={'/images/footer/a16z-icon.svg'} alt="a16z" width={69} height={16} />
            <div className="ff-icon">
              <Image src={'/images/footer/ff-icon.svg'} alt="ff" width={12} height={12} />
              <Image src={'/images/footer/ff-text.svg'} alt="ff" width={99} height={8} />
            </div>
            <Image src={'/images/footer/m13-icon.svg'} alt="m13" width={34} height={16} />
          </div>
        </div>
        <div className="footer-columns">
          <div className="footer-column">
            <h2>Explore</h2>
            <Link className="column-item" href={'/'}>
              Home
            </Link>
            <Link className="column-item" href={'/plans-and-pricing'}>
              Pricing
            </Link>
            <Link className="column-item" href={'/blog'}>
              Blog
            </Link>
            <Link className="column-item" href={'/careers'}>
              Careers
            </Link>
            <Link className="column-item" href={'/pietra-partners-new'}>
              Be a supplier
            </Link>
          </div>
          <div className="footer-column">
            <h2>Help</h2>
            <span className="column-item" onClick={showIntercom}>
              Contact us
            </span>
            <Link className="column-item" href={FAQ_LINK}>
              FAQ
            </Link>
            <Link className="column-item" href={SUPPLIER_FAQ_LINK}>
              Supplier FAQ
            </Link>
          </div>
          <div className="footer-column">
            <h2>Community</h2>
            <Link className="column-item" href={SLACK_COMMUNITY_LINK} target="_blank">
              Slack
            </Link>
            <Link className="column-item" href={BEGINNER_WORKSHOP_LINK} target="_blank">
              Business 101 Workshop
            </Link>
            <Link className="column-item" href={AFFILIATE_LINK} target="_blank">
              Affiliate Program
            </Link>
          </div>
          <div className="footer-column">
            <h2>Start a business</h2>
            <Link className="column-item" href={BUSINESS_BUILDER_LINK} target="_blank">
              Business builder
            </Link>
            <Link className="column-item" href="/platform/business-kit">
              Create a brand kit
            </Link>
          </div>
          <div className="footer-column">
            <h2>Scale your business</h2>
            <Link className="column-item" href="/platform/sourcing-and-production/products">
              Sourcing & production
            </Link>
            <Link className="column-item" href="/platform/logistics-and-fulfillment">
              Logistics & fulfillment
            </Link>
            <Link className="column-item" href="/platform/sales-and-marketing">
              Sales & marketing
            </Link>
            <Link className="column-item" href="/existing-brands">
              Scaling with Pietra
            </Link>
          </div>
          <div className="footer-column">
            <h2>Popular</h2>
            <div className="footer-column-popular">
              <Link className="column-item" href={'/l/finding-clothing-manufacturers'}>
                Finding clothing manufacturers
              </Link>
              <Link className="column-item" href={'/l/open-your-pietra-storefront'}>
                Open your Pietra storefront
              </Link>
              <Link className="column-item" href={'/l/start-a-clothing-line'}>
                Start a clothing line
              </Link>
              <Link className="column-item" href={'/l/start-a-jewelry-line'}>
                Start a jewelry line
              </Link>
              <Link className="column-item" href={'/l/start-a-skincare-line'}>
                Start a skincare line
              </Link>
              <Link className="column-item" href={'/l/start-a-makeup-line'}>
                Start a makeup line
              </Link>
              <Link className="column-item" href={'/l/start-a-hair-products-line'}>
                Start a hair products line
              </Link>
              <Link className="column-item" href={'/l/start-a-lip-gloss-line'}>
                Start a lip gloss line
              </Link>
              <Link className="column-item" href={'/l/start-an-eyeshadow-line'}>
                Start an eye shadow line
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="pietra-footer-content">
        <div className="icons">
          <Link href="https://www.instagram.com/shoppietra" target="_blank">
            <Image src={'/images/footer/instagram-icon.svg'} alt="instagram" width={ICON_SIZE} height={ICON_SIZE} />
          </Link>
          <Link href="https://www.facebook.com/ShopPietra/" target="_blank">
            <Image src={'/images/footer/facebook-icon.svg'} alt="facebook" width={ICON_SIZE} height={ICON_SIZE} />
          </Link>
          <Link href="https://twitter.com/shoppietra" target="_blank">
            <Image src={'/images/footer/twitter-icon.svg'} alt="twitter" width={ICON_SIZE} height={ICON_SIZE} />
          </Link>
          <Link href="https://www.tiktok.com/@shoppietra" target="_blank">
            <Image src={'/images/footer/tiktok-icon.svg'} alt="tiktok" width={ICON_SIZE} height={ICON_SIZE} />
          </Link>
          <Link href="https://www.linkedin.com/company/pietra-marketplace" target="_blank">
            <Image src={'/images/footer/linkedin-icon.svg'} alt="linkedin" width={ICON_SIZE} height={ICON_SIZE} />
          </Link>
        </div>
        <div className="bottom-container">
          <div className="link-item">
            <Image
              src={'/images/footer/stone-icon.svg'}
              alt="pietra-stone"
              width={ICON_SIZE - 8}
              height={ICON_SIZE - 8}
            />
            Copyright {currentYear}. Pietra, Inc.
          </div>
          <div className="links">
            <Link
              href="https://docs.google.com/document/d/10psgFfol8WrLfsp-yAwcW-dAdDVnkRgapm8CBh4L-9E"
              target="_blank"
              className="link-item"
            >
              Privacy policy
            </Link>
            <Link
              href="https://docs.google.com/document/d/1xyqmahqh73t9l9xV2VixOFbcli58saOktL_Ppn2RWyE"
              target="_blank"
              className="link-item"
            >
              Terms of service
            </Link>
            <Link
              href="https://docs.google.com/document/d/1Yn0yUGaerTKdgeDqqf0qyXsqRr6tZujMCA1AV7vf1CE"
              target="_blank"
              className="link-item"
            >
              Fulfillment services agreement
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
