import axios from 'axios';
import getConfig from 'next/config';
/* Annotate message because we do not need to introduce antd 
   in the browser environment */
// import { message } from 'antd';
import storage from '@/shared/utils/storage';
import { CREATE_LOGIN_TOKEN } from '@/shared/constants';

const { publicRuntimeConfig } = getConfig();
// message.config({
//   top: 60,
//   prefixCls: 'pietra-message',
// });

const instance = axios.create({
  baseURL: publicRuntimeConfig.NEXT_PUBLIC_API_HOST,
  timeout: 10000,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (typeof window === 'object') {
      // message.destroy();
      // message.error('Sorry, something went wrong. Please try again.');
    }
    return Promise.reject(error);
  },
);

instance.interceptors.request.use(
  (config) => {
    if (config?.headers?.['Ingnore-Authorization']) {
      delete config.headers['Ingnore-Authorization'];
    } else {
      const token = storage.get(CREATE_LOGIN_TOKEN);
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export function updateAuthorization(token) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export default instance;

export const hostname = publicRuntimeConfig;

export function getAuthorization() {
  const token = storage.get(CREATE_LOGIN_TOKEN);
  return token ? `Bearer ${token}` : null;
}

const instanceWithoutAuth = axios.create({
  baseURL: publicRuntimeConfig.NEXT_PUBLIC_API_HOST,
  timeout: 10000,
});

instanceWithoutAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const axiosWithoutAuth = instanceWithoutAuth;
