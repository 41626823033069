const getSlackItem = (item: string, description: string) => {
  if (description) return item + ': ' + description.replace(/[,.]$/, '');
  return '';
};

export const getSlackItemList = (itemList: string[][]) => {
  const slackItemList = [];
  for (let i = 0; i < itemList.length; i++) {
    const item = itemList[i];
    const slackItem = getSlackItem(item[0], item[1]);
    if (slackItem) slackItemList.push(slackItem);
  }
  return slackItemList;
};
