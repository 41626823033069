export const FILE_SIZE_LIMIT = 20;

export const FAQ_LINK = 'https://help.pietrastudio.com';
export const STATIC_FILE_URL = 'https://static.pietrastudio.com/public/file_uploads';
export const SUPPLIER_FAQ_LINK = 'https://help.pietrastudio.com/en/collections/9251620-pietra-suppliers-faq';

export const SERVER_STATUS_CODES = {
  SUCCESS: 200,
  CREATED: 201,
  ACCEPTED: 202,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  LOCKED: 423,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  TIMEOUT: 504,
};

export const PREVIEW_MODE_SERVER_ERROR = "Preview mode can't take this action.";
export const PREVIEW_MODE_QUERY_PARAM = '__preview__';
