import _ from 'lodash';
import { message } from 'antd';
import { encodeURL } from 'js-base64';
import { PREVIEW_MODE_SERVER_ERROR } from '@/shared/constants/web-constants';
import { ANONYMOUS_LOGIN_TOKEN, CREATE_LOGIN_TOKEN } from '@/shared/constants';
import storage from '@/shared/utils/storage';
import { sessionStorageService } from '@/lib/service/storage';

export function token() {
  const { getStorage } = sessionStorageService(ANONYMOUS_LOGIN_TOKEN);
  return storage.get(CREATE_LOGIN_TOKEN) || getStorage() || null;
}

export function toLogin() {
  sessionStorage.clear();
  localStorage.clear();

  const isLoginOrRegisterPage = ['/login', '/register'].includes(window.location.pathname);
  if (!isLoginOrRegisterPage) {
    window.location.href = `${location.origin}/login?path=${encodeURL(location.href.replace(location.origin, ''))}`;
  }
}

export function scrollTo(elemId: string, time?: number, block?: ScrollLogicalPosition) {
  setTimeout(() => {
    const domElement = document.getElementById(elemId.replace('#', ''));
    if (domElement && 'scrollIntoView' in domElement) {
      domElement.scrollIntoView({ behavior: 'smooth', block: block || 'start' });
    }
  }, time || 200);
}

export function scrollToTop(elemClass: string) {
  const modals = document.querySelectorAll(elemClass);
  if (modals) {
    modals.forEach((modal: any) => (modal.scrollTop = 0));
  }
}

export const showRequestError = (err: any, msg?: string) => {
  const messageToShow = err?.response?.data?.message || err?.data?.message || msg;
  if (messageToShow === PREVIEW_MODE_SERVER_ERROR) {
    return;
  }
  message.error(messageToShow || 'There was an error. Please try again later.');
};
