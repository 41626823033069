import { SalesChannelConnectedState } from './e-commerce';

export interface ShopifyAppInfo {
  scope: string;
  setup: boolean;
  shopName: string;
  connected: boolean;
  accessToken: string;
}

export enum StripeSubscriptionStatus {
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  UNPAID = 'unpaid',
  CANCELED = 'canceled',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
}

export interface DesignerBrandInfo {
  homeBackground: string;
  homeMobileBackground: string;
  city: string;
  state: string;
  theme: number;
  online: boolean;
  endDate: string;
  favicon: string;
  activated: boolean;
  homeTitle: string;
  startDate: string;
  topProduct: number;
  twitterUrl: string;
  youtubeUrl: string;
  instagramUrl: string;
  homeDescription: string;
  homeBackgroundColor: string;
}

export interface BankInformation {
  id: number;
  storeId: number;
  catalogCompanyId?: any;
  zip?: any;
  city?: any;
  fein?: any;
  iban?: any;
  state?: any;
  paypal?: any;
  country?: any;
  bankName?: any;
  currency: string;
  intlBank?: any;
  sortCode?: any;
  bankAddress?: any;
  companyName?: any;
  swiftBicCode?: any;
  routingNumber?: any;
  companyAddress?: any;
  bankAccountNumber?: any;
  institutionNumber?: any;
  billingEmailAddress?: any;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreditCard {
  id: number;
  storeId: number;
  number: string;
  expireMonth: number;
  expireYear: number;
  cvc: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface User {
  id: number;
  username: string;
  name: string;
  firstName: string;
  lastName: string;
  openAiUsedCredits?: number;
  openAiTotalCredits?: number;
}

export enum ShippingRateOption {
  LOW_VOLUME_VARIABLE = 'LOW_VOLUME_VARIABLE',
  LOW_VOLUME_FLAT = 'LOW_VOLUME_FLAT',
  HIGH_VOLUME_VARIABLE = 'HIGH_VOLUME_VARIABLE',
  PREMIUM_UPS = 'PREMIUM_UPS',
}

export interface Config {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  sendSalesToShopify?: boolean;
  enabledShopifyStockPush?: boolean;
  ccEmails?: string[];
  logisticsFeeRate?: number;
  joinedIGState?: SalesChannelConnectedState;
  connectedVerishopState?: SalesChannelConnectedState;
  connectedWholesaleState?: SalesChannelConnectedState;
  googleShoppingConnectedState?: SalesChannelConnectedState;
  packingSlipLogo?: string;
  fulfillmentFee?: number;
  shopifySalesFeePercent?: number;
  assemblyFee?: number;
  photographyFee?: number;
  shippingRates?: ShippingRateOption;
}

export enum Behavior {
  KEEP_AS_DRAFT = 'keep_as_draft',
  MARK_UNCOLLECTIBLE = 'mark_uncollectible',
  VOID = 'void',
}

export interface StoreMe {
  id: number;
  handle: string;
  title: string;
  avatarUrl: string;
  logo?: any;
  email: string;
  platformShopifyCollectionId: string;
  shopifyAppInfo: ShopifyAppInfo;
  ourStory: string;
  servicesOffered?: any;
  shippingAndReturnPolicy?: any;
  phoneNo: string;
  experienceNo: number;
  mediaUrls: string[];
  instagram: string;
  instagramFollowers?: any;
  website?: any;
  yelpOrGoogle?: any;
  accountType: StoreAccountType;
  interestedServices: InterestedServicesType[];
  addressName: string;
  addressCountry: string;
  addressState: string;
  addressCity: string;
  addressDetail?: any;
  addressDetail2?: any;
  addressPostalCode: string;
  isEnabled: boolean;
  existingEcommerceProviders?: any;
  bannerText?: any;
  youtubeAccount: string;
  youtubeFollowers?: any;
  tiktokAccount: string;
  tiktokFollowers?: any;
  snapchatAccount?: any;
  snapchatFollowers?: any;
  twitterAccount?: any;
  twitterFollowers?: any;
  comingSoonData?: any;
  plans: string[];
  planType: PlanType;
  hasPaidBlueprintFee: boolean;
  designerBrandInfo: DesignerBrandInfo;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: any;
  externalFields: any[];
  bankInformation: BankInformation;
  creditCard: CreditCard;
  user: User;
  socialProfile: {
    picture: string;
    name: string;
    followers: number;
  };
  config: Config;
  blueprintSubscriptionId: string;
  blueprintSubscriptionStatus: StripeSubscriptionStatus;
  blueprintSubscriptionPausedAt: string;
  blueprintSubscriptionResumesAt: string;
  blueprintSubscriptionBehavior: Behavior;
  blueprintSubscriptionPeriodStartAt: string;
  blueprintSubscriptionPeriodEndAt: string;
  isPreviewAccount?: boolean;
}

// The different pricing tiers of Pietra.
export enum PlanType {
  // Default Free Plan
  TIER_1 = 'TIER_1',
  // Beginner Plan
  TIER_2 = 'TIER_2',
  // Scaling Plan
  TIER_2_5 = 'TIER_2_5',
  //  Professional Plan
  TIER_3 = 'TIER_3',
}

export enum ExternalFieldName {
  STORE_PROFILE_QUESTIONS = 'storeProfileQuestions',
  SEEN_PRODUCT_TOUR = 'seenProductTour',
  SEEN_SOURCING_MARKETPLACE = 'seenSourcingMarketplace',
  SUPPLIER_TAGS = 'supplierTags',
  SUPPLIER_NOTES = 'supplierNotes',
  INTERESTED_SERVICES = 'interestedServices',
}

export interface ExternalField {
  id: number;
  storeId: number;
  name: ExternalFieldName;
  value: any;
  createdAt: Date;
  updatedAt: Date;
}

export interface StoreProfileQuestion {
  question: string;
  answer: string;
}

export enum StoreSetupTab {
  PRODUCTS = 'products',
  PROFILE = 'profile',
  COMING_SOON_PAGE = 'coming-soon-page',
  DISCOUNTS = 'discounts',
  SUBSCRIBERS = 'subscribers',
}

export enum StoreAccountType {
  CREATOR = 'CREATOR',
  SMB = 'SMB',
  UNDECIDED = 'UNDECIDED',
}

export enum InterestedServicesType {
  SOURCING = 'SOURCING',
  FULFILLMENT = 'FULFILLMENT',
  SALES_CHANNEL = 'SALES_CHANNEL',
  SUPPLIER_MANAGEMENT = 'SUPPLIER_MANAGEMENT',
}

export interface LogisticsFeeRate {
  rate: number;
  ladder: number;
}

export interface Store {
  id: number;
  handle: string;
  title: string;
  fullName: string;
  avatarUrl: string;
  logo?: any;
  ourStory: string;
  servicesOffered: string;
  shippingAndReturnPolicy: string;
  experienceNo: number;
  mediaUrls: string[];
  instagram?: any;
  instagramFollowers?: any;
  website?: any;
  yelpOrGoogle?: any;
  addressName?: any;
  addressCountry?: any;
  addressState: string;
  addressCity: string;
  addressPostalCode?: any;
  isEnabled: boolean;
  enabledAt: Date;
  globalSort: number;
  existingEcommerceProviders?: any;
  bannerText?: any;
  youtubeAccount?: any;
  youtubeFollowers?: any;
  tiktokAccount?: any;
  tiktokFollowers?: any;
  snapchatAccount?: any;
  snapchatFollowers?: any;
  twitterAccount?: any;
  twitterFollowers?: any;
  comingSoonData?: any;
  planType: string;
  hasPaidBlueprintFee?: any;
  isBlueprintTrail?: any;
  blueprintSubscriptionId?: any;
  blueprintSubscriptionStatus?: any;
  blueprintSubscriptionPeriodStartAt?: any;
  blueprintSubscriptionPeriodEndAt?: any;
  blueprintSubscriptionPausedAt?: any;
  blueprintSubscriptionBehavior?: any;
  blueprintSubscriptionResumesAt?: any;
  tags: string[];
  accountType: string;
  interestedService: string;
  businessPlanCompletedAt?: any;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: any;
  externalFields: any[];
  shopifyAppInfo?: any;
  config: Config;
}

export interface EmbedJewelryItem {
  id: number;
  name: string;
  storeId: number;
  isEnabled: boolean;
  syncData: [Object];
  store: [Object];
  categories: null;
  gemstones: [];
  materials: [];
  coverUrls: string[];
}

export interface BookDemo {
  id: number;
  firstName: string;
  lastName: string;
  brandName: string;
  websiteUrl: string;
  email: string;
  phone: string;
  identifyYourself: string;
  teamSize: string;
  interestedServices: string[];
  currentlySourcing: string;
  currentlyFulfillment: string;
  monthlyFulfillmentVolume: string;
  other: string;
  updatedAt: string;
  createdAt: string;
}

export enum StoreIdentifyYourselfValue {
  STARTING_OUT = 'STARTING_OUT',
  YEARLY_SALES_0K_10K = 'YEARLY_SALES_0K_10K',
  YEARLY_SALES_10K_100K = 'YEARLY_SALES_10K_100K',
  YEARLY_SALES_100K_500K = 'YEARLY_SALES_100K_500K',
  YEARLY_SALES_500K_2M = 'YEARLY_SALES_500K_2M',
  YEARLY_SALES_2M_5M = 'YEARLY_SALES_2M_5M',
  YEARLY_SALES_5M_ABOVE = 'YEARLY_SALES_5M_ABOVE',
}
