export const WEBSITE_URL = 'https://www.pietrastudio.com';
export const STATIC_FILE_URL = 'https://static.pietrastudio.com/public/file_uploads';

export const CREATE_LOGIN_TOKEN = 'create-login-token';
export const ANONYMOUS_LOGIN_TOKEN = 'anonymous-login-token';
export const CAMPAIGN_DATA = 'campaign-data';
export const ANONYMOUS_USER_ID = 'anonymousUserId';
export const SIGN_UP_HOMEPAGE_INTEREST = 'HOMEPAGE_INTEREST';

export const PIETRA_LOGO_STONE =
  'https://static.pietrastudio.com/public_compact/file_uploads/bcdc16841c1489bc47546cd9cbbb250c.png';

export const PIETRA_LOGO_SQUARE = `${STATIC_FILE_URL}/e7f65754159dc71423464b1e2990424f.png`;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum LocalStorage {
  SEEN_HOMEPAGE_BANNER = 'seenHomepageBanner',
  INTERACTED_WITH_SURVEY_BANNER = 'interactedWithSurveyBanner',
  SUBSCRIPTION_PARAM_CACHE = 'SUBSCRIPTION_PARAM_CACHE',
  SUBSCRIPTION_SOURCES = 'SUBSCRIPTION_SOURCES',
  SHOW_MANAGE_SHOP_TOUR = 'SHOW_MANAGE_SHOP_TOUR',
  NEW_APP_VERSION = 'NEW_APP_VERSION',
}
