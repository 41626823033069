import { CatalogCategory } from './catalog';
import { VerticalName } from './category';

export enum SupplierProfileTab {
  CONTACT = 'contact',
  CAPABILITIES = 'capabilities',
  REVIEWS = 'reviews',
}

export interface IAttachment {
  fileName: string;
  fileUrl: string;
}

export interface SupplierProfileInstructionalVideo {
  vimeoId: number;
  thumbnailImage: string;
  title: string;
  timeLength: string;
}

export interface SupplierProfilePdfListItem {
  title: string;
  image: string;
}

export enum PDPContentTab {
  PRODUCT_DESCRIPTION = 'PRODUCT_DESCRIPTION',
  SUPPLIER_CAPABILITIES = 'SUPPLIER_CAPABILITIES',
  SIMILAR_PRODUCTS = 'SIMILAR_PRODUCTS',
  REVIEWS = 'REVIEWS',
}

export enum SupplierProfileProductsSort {
  NEW = 'new',
  FEATURED = 'featured',
}

export declare type CustomizationType = 'text' | 'colors' | 'tags' | 'upload_image' | 'image_opts';

export interface ICustomization {
  identifier: string;
  title: string;
  value: any;
  type: CustomizationType;
  exampleImages?: string | string[];
}

export interface IColor {
  identifier: string;
  name: string;
  colorVariant: string;
  hexCode: string;
}

export interface IOption {
  identifier?: string;
  name?: string;
  image?: string;
  color?: string;
  value?: string;
}

export enum SourcingHomeTab {
  ALL = 'All',
  PRODUCTS = 'Products',
  PACKAGING = 'Ecommerce_Packaging',
  BUSINESS_AND_CREATIVE_SERVICES = 'Business_&_Creative_Services',
  WEBSITE_DEVELOPMENT = 'Website_Development',
}

export enum ViewType {
  SUPPLIERS = 'SUPPLIERS',
  CATALOG_PRODUCTS = 'CATALOG_PRODUCTS',
  RFP = 'RFP',
}

export interface HomeCard {
  preheader: string;
  header: string;
  factoryCount?: number;
  logos?: string[];
}

export enum SamplePriceFilterOption {
  ALL = 'All',
  FIVE_TO_FIFTY = '$5-$50',
  FIFTY_TO_HUNDRED = '$50-$100',
  HUNDRED_PLUS = '$100+',
  NO_SAMPLE_PRICE = 'Custom quote (price tbd)',
}

export enum MoqFilterOption {
  ALL = 'All',
  ZERO_TO_HUNDRED = '0-100 units',
  HUNDRED_TO_THOUSAND = '100-1000 units',
  THOUSAND_PLUS = '1000+ units',
  NO_MOQ = 'Custom quote (MOQ tbd)',
}

export interface AutocompleteCompany {
  id: number;
  avatarUrl: string;
  name: string;
  category?: {
    vertical: VerticalName;
    group: string;
    subgroup: string;
  };
}

export enum SourcingMarketplaceSearchType {
  HOMEPAGE = 'HOMEPAGE',
  BROWSE_MARKETPLACE = 'BROWSE_MARKETPLACE',
}

export interface SubcategoryMap {
  [verticalName: string]: { [groupName: string]: { [subgroupName: string]: CatalogCategory } };
}

export enum PackagingTab {
  GET_IN_8_10_DAYS = 'Get in 8-10 days',
  LOW_COST_OPTIONS = 'Low cost options',
  BY_CATEGORY = 'By category',
}

export interface SourcingMarketplaceBannerValueProp {
  icon: string;
  title: string;
  description: string;
  classname: string;
}
