import { createRoot } from 'react-dom/client';
import { Image } from 'antd';
import { useState } from 'react';

const PreviewImageContent = ({
  images,
  initCurrent = 0,
  onDestroy,
}: {
  images: Array<string>;
  initCurrent?: number;
  onDestroy: () => void;
}) => {
  const [current, setCurrent] = useState<number>(initCurrent);
  const handleChange = (current: number) => {
    setCurrent(current);
  };

  return (
    <Image.PreviewGroup preview={{ visible: true, onVisibleChange: onDestroy, current, onChange: handleChange }}>
      {images.map((src, index) => (
        <Image key={`preview-image-item-${index}`} src={src} alt={`preview-image-item-${index}`} />
      ))}
    </Image.PreviewGroup>
  );
};

const PreviewImage = ({ images, current = 0 }: { images: Array<string>; current?: number }) => {
  const container = document.createDocumentFragment();
  const root = createRoot(container);
  const destroy = () => {
    root.unmount();
  };

  const render = () => {
    root.render(<PreviewImageContent images={images} initCurrent={current} onDestroy={destroy} />);
  };
  render();
  return {
    destroy,
  };
};

/**
 * 上传图标的图片预览
 * @param props
 */
export const handlePreview = ({ urls, current }: { urls: Array<string>; current?: number }) => {
  if (urls && urls.length > 0) {
    PreviewImage({ images: urls, current });
  }
};

export default PreviewImage;
