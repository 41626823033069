import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';

Builder.registerComponent(
  dynamic(() => import('./components/Counter/Counter')),
  {
    name: 'Counter',
    inputs: [
      {
        name: 'initialCount',
        type: 'number',
      },
    ],
  },
);

Builder.registerComponent(
  dynamic(() => import('./components/Home/HomepageV2/BrandLogos')),
  {
    name: 'BrandLogos',
  },
);

Builder.registerComponent(
  dynamic(() => import('./components/Home/HomepageV2/TextWithHighlight')),
  {
    name: 'TextWithHighlight',
  },
);

Builder.registerComponent(
  dynamic(() => import('./components/Home/HomepageV2/HomepageHero')),
  {
    name: 'HomepageHero',
    inputs: [
      {
        name: 'title',
        type: 'string',
        required: false,
      },
      {
        name: 'desc',
        type: 'string',
        required: false,
      },
      {
        name: 'btnText',
        type: 'string',
        required: false,
      },
    ],
  },
);

Builder.registerComponent(
  dynamic(() => import('./components/Partners/FrequentlyAskedQuestions')),
  {
    name: 'FrequentlyAskedQuestions',
  },
);

Builder.registerComponent(
  dynamic(() => import('./components/Common/LandingPage/MarkingMoneyBrand')),
  {
    name: 'MarkingMoneyBrand',
    inputs: [
      {
        name: 'buttonText',
        type: 'string',
        required: false,
      },
    ],
  },
);

Builder.registerComponent(
  dynamic(() => import('./components/Home/HomepageV2/HomepageHeroWithBrandVideo')),
  {
    name: 'HomepageHeroWithBrandVideo',
    inputs: [
      {
        name: 'title',
        type: 'string',
        required: false,
      },
      {
        name: 'btnText',
        type: 'string',
        required: false,
      },
      {
        name: 'className',
        type: 'string',
        required: false,
      },
      {
        name: 'featureCard1',
        type: 'object',
        required: false,
        subFields: [
          { name: 'title', type: 'text', required: false },
          { name: 'description', type: 'text', required: false },
          { name: 'image', type: 'text', required: false },
          { name: 'link', type: 'text', required: false },
          {
            name: 'hover',
            type: 'object',
            required: false,
            subFields: [
              {
                name: 'withoutPietra',
                type: 'list',
                required: false,
                subFields: [{ name: 'item', type: 'text', required: false }],
              },
              {
                name: 'withPietra',
                type: 'list',
                required: false,
                subFields: [{ name: 'item', type: 'text', required: false }],
              },
              {
                name: 'start',
                type: 'text',
                required: false,
              },
            ],
          },
        ],
      },
      {
        name: 'featureCard2',
        type: 'object',
        required: false,
        subFields: [
          { name: 'title', type: 'text', required: false },
          { name: 'description', type: 'text', required: false },
          { name: 'image', type: 'text', required: false },
          { name: 'link', type: 'text', required: false },
          {
            name: 'hover',
            type: 'object',
            required: false,
            subFields: [
              {
                name: 'withoutPietra',
                type: 'list',
                required: false,
                subFields: [{ name: 'item', type: 'text', required: false }],
              },
              {
                name: 'withPietra',
                type: 'list',
                required: false,
                subFields: [{ name: 'item', type: 'text', required: false }],
              },
              {
                name: 'start',
                type: 'text',
                required: false,
              },
            ],
          },
        ],
      },
      {
        name: 'featureCard3',
        type: 'object',
        required: false,
        subFields: [
          { name: 'title', type: 'text', required: false },
          { name: 'description', type: 'text', required: false },
          { name: 'image', type: 'text', required: false },
          { name: 'link', type: 'text', required: false },
          {
            name: 'hover',
            type: 'object',
            required: false,
            subFields: [
              {
                name: 'withoutPietra',
                type: 'list',
                required: false,
                subFields: [{ name: 'item', type: 'text', required: false }],
              },
              {
                name: 'withPietra',
                type: 'list',
                required: false,
                subFields: [{ name: 'item', type: 'text', required: false }],
              },
              {
                name: 'start',
                type: 'text',
                required: false,
              },
            ],
          },
        ],
      },
      {
        name: 'featureCard4',
        type: 'object',
        required: false,
        subFields: [
          { name: 'title', type: 'text', required: false },
          { name: 'description', type: 'text', required: false },
          { name: 'image', type: 'text', required: false },
          { name: 'link', type: 'text', required: false },
          {
            name: 'hover',
            type: 'object',
            required: false,
            subFields: [
              {
                name: 'withoutPietra',
                type: 'list',
                required: false,
                subFields: [{ name: 'item', type: 'text', required: false }],
              },
              {
                name: 'withPietra',
                type: 'list',
                required: false,
                subFields: [{ name: 'item', type: 'text', required: false }],
              },
              {
                name: 'start',
                type: 'text',
                required: false,
              },
            ],
          },
        ],
      },
      { name: 'platformTitle', type: 'text', required: false },
      { name: 'brandTitle', type: 'text', required: false },
      {
        name: 'brandList',
        type: 'list',
        required: false,
        subFields: [
          { name: 'logoWidth', type: 'number', required: false },
          { name: 'logoHeight', type: 'number', required: false },
          { name: 'logoAlt', type: 'text', required: false },
          { name: 'logoSrc', type: 'text', required: false },
          { name: 'videoPoster', type: 'text', required: false },
          { name: 'videoSrc', type: 'text', required: false },
        ],
      },
    ],
  },
);

Builder.registerComponent(
  dynamic(() => import('./components/Home/HomepageV2/BrandImageCarousel')),
  {
    name: 'BrandImageCarousel',
    inputs: [
      {
        name: 'className',
        type: 'string',
        required: false,
      },
      { name: 'brandTitle', type: 'text', required: false },
      {
        name: 'brandList',
        type: 'list',
        required: false,
        subFields: [
          { name: 'logoWidth', type: 'number', required: false },
          { name: 'logoHeight', type: 'number', required: false },
          { name: 'logoAlt', type: 'text', required: false },
          { name: 'logoSrc', type: 'text', required: false },
          { name: 'posterSrc', type: 'text', required: false },
        ],
      },
    ],
  },
);
