import React, { useState } from 'react';
import { FAQType } from '@/shared/types/subscription';
import { DownOutlined, UpOutlined } from '@/components/Common/Icons';
import classNames from 'classnames';
import xss from 'xss';
import './Faq.less';

interface IProps {
  faq: FAQType;
  className?: string;
  CollapseIcon?: React.FC;
  ExpandIcon?: React.FC;
}

const DefaultCollapseIcon: React.FC = () => <UpOutlined color="black" className="answer-display-toggler" />;

const DefaultExpandIcon: React.FC = () => <DownOutlined color="black" className="answer-display-toggler" />;

const Faq = ({ faq, className, CollapseIcon = DefaultCollapseIcon, ExpandIcon = DefaultExpandIcon }: IProps) => {
  const [displayAnswer, setDisplayAnswer] = useState<boolean>(false);
  return (
    <div className={classNames('faq', className)} onClick={() => setDisplayAnswer(!displayAnswer)}>
      <div className="question-container">
        <h3 className="faq-question">{faq.question}</h3>
        {displayAnswer ? <CollapseIcon /> : <ExpandIcon />}
      </div>
      <div className={classNames('faq-answer-container', { visible: displayAnswer })}>
        <p className="faq-answer" dangerouslySetInnerHTML={{ __html: xss(faq.answer) }} />
      </div>
    </div>
  );
};

export default Faq;
