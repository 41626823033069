import React, { useEffect } from 'react';
import Script from 'next/script';
import dynamic from 'next/dynamic';
import { useIntercom } from 'react-use-intercom';
import { useRouter } from 'next/router';
import { builder } from '@builder.io/react';
import 'src/builder-registry';
import './index.less';

builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY!);
const Footer = dynamic(() => import('@/components/Common/Layout/Footer'));
const Header = dynamic(() => import('@/components/Common/Layout/Header'));

interface LayoutProps {
  structured?: { [key: string]: string };
  children: React.ReactNode;
  hideFooter?: boolean;
  hideHeader?: boolean;
  largeHeader?: boolean;
}

export default function Layout({
  children,
  structured,
  hideFooter = false,
  hideHeader = false,
  largeHeader = false,
}: LayoutProps) {
  const { boot, update, isOpen, shutdown } = useIntercom();

  const router = useRouter();

  const handleRouteChange = () => {
    if (isOpen) {
      update();
    }
  };

  useEffect(() => {
    boot();
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pietra-layout" id="pietra-layout-root">
      <Script
        id="application/ld+json"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structured) }}
      />
      {!hideHeader && <Header isLarge={largeHeader} showIntercom={boot} hideIntercom={shutdown} />}
      <main className={hideHeader ? 'hide-header' : ''}>{children}</main>
      {!hideFooter && <Footer />}
    </div>
  );
}
