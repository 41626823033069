import './index.less';

const MeshBackgroundWithNoise = () => {
  return (
    <div className="mesh-bg-with-noise">
      <div className="background" />
      <div className="noise" />
    </div>
  );
};

export default MeshBackgroundWithNoise;
