import { AxiosRequestConfig } from 'axios';

export interface AxiosRequestConfigExtendsProps extends AxiosRequestConfig {
  times?: number;
}

export enum IfCreateRequestProps {
  BaseOnResponse = 0,
  Force = 1,
  Once = 2,
}

export interface PollRequestProps {
  config: AxiosRequestConfigExtendsProps;
  maxAttempts: number;
  interval: number;
  ifCreateRequest?: IfCreateRequestProps;
}
