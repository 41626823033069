export interface UtilSendSlackMessageRequestProps {
  text: string;
  channel: string;
}

export interface UtilSendSlackMessageResponseProps {
  success: boolean;
}

export enum SlackChannel {
  BRANDKIT = 'BRANDKIT',
  AI_PRODUCT = 'AI_PRODUCT',
  COMMUNITY_BUSINESS_KIT = 'COMMUNITY_BUSINESS_KIT',
  AI_DESIGN = 'AI_DESIGN',
}
